<template>
  <div class="bank">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">我的信用</div>
    <div class="card">
      <img src="../../assets/img/wdxy.jpg" alt="">
      <p>“桂信融”平台为平台注册并完成实名认证的企业生成《企业综合信息报告》，全面展示企业的社会信用情况，包括企业基本信息、司法信息、行政执法信息、企业经营信息、知识产权信息等多方面内容。在开展金融活动、商业活动时，企业可通过“桂信码”扫码授权等方式，在线授权交易对手查询自身《企业综合信息报告》，向交易对手多角度、全方位展示自身信用状况，提升交易成功率。</p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
   setup() {
    return { 
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.bank {
  padding-top: 12.5vw;
  .card {
    img {
      width: 100%;
      height: 45vw;
      border-radius: 1.5vw;
    }
    p {
      margin-top: 3vw;
      font-size: 4vw;
      letter-spacing: 1px;
      line-height: 6vw;
      span {
        color: #ff0000
      }
    }
  }
}
</style>
